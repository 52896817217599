import {Inject, Injectable} from '@angular/core';
import {EtsDetailData} from './ets-detail-data';
import {BehaviorSubject} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {EtsConfigService} from '../ets-config-service/ets-config.service';
import { LogService } from '../services/LogService.service';

@Injectable({
  providedIn: 'root'
})
export class EtsDetailService {

  detailDataStorage: {[identifier: string]: EtsDetailData};
  subject: {[identifier: string]: BehaviorSubject<EtsDetailData>};

  public constructor(@Inject('ETS_API_URL') public apiUrl: string, private http: HttpClient, private config: EtsConfigService, private logService: LogService) {
    this.detailDataStorage = {};
    this.subject = {};
  }

  /**
   * Gets event informations like eventlocation, host, etc.
   *
   * @param identifier string
   * @returns BehaviourSubject<EtsDetailData>
   */
  public loadDetailData(identifier: string): BehaviorSubject<EtsDetailData> {
    if (this.subject.hasOwnProperty(identifier)) {
      return this.subject[identifier];
    } else {
      this.subject[identifier] = new BehaviorSubject<EtsDetailData>(null);
    }

    this.config.getShopIdentifierSubject().subscribe(conf => {
      if (conf) {
        const promise = this.http.get(this.apiUrl + 'events/' + parseInt(identifier, 10), {headers: {partnershopId: this.config.getShopIdentifier()}}).toPromise();

        promise.then((data: any) => {
          if (data.data && data.data.event) {
            const event = data.data.event;
            const eventDate = data.data;
            const location = data.data.location;
            const images = data.data.images;
            const imageList = [];

            for (const imageData of images) {
              if (imageData.paths) {
                for (const path of imageData.paths) {
                  if (path.type === 'large') {
                    if (!path.url.endsWith('/')) {
                      imageList.push({
                        url: path.url,
                        title: imageData.title,
                        copyright: imageData.copyright,
                      });
                    }
                  }
                }
              }
            }

            this.detailDataStorage[identifier] = {
              base: {
                artist: event.event_title_1
              },
              headliner: {
                photoTitle: 'Photo Title',
                photoCopyright: 'Copyright',
                photoUrls: imageList,
                infoTitle: event.event_title_1,
                infoSubTitle: event.event_title_2,
                infoDateString: eventDate.event_date_date_time,
                infoLocationString: location.location_name,
                infoPriceBegin: eventDate.event_date_price_start,
                infoPriceEnd: eventDate.event_date_price_end,
                shortDescription: event.show_event_date_description ? eventDate.description : event.event_description,
                shortDescriptionEN: event.show_event_date_description ? eventDate.event_date_description_en : event.event_description_en,
                packages: eventDate.packages ? eventDate.packages : [],
                eventLink: event.event_link,
                eventLinkTarget: event.event_link_target,
                eventLinkText: event.event_link_text,
                eventVideo: event.event_video_code
              },
              infoBox: {
                packageInfoText: '',
                moreInfoHTML: (data.data.event.event_additional_info) ? data.data.event.event_additional_info : '',
                artistHTML: '',
                locationHTML: (data.data.location.location_name ? '<div class="name">' + data.data.location.location_name + '</div>' : '') +
                  (data.data.location.location_street ? '<div class="street">' + data.data.location.location_street + '</div>' : '') +
                  (data.data.location.location_postal_code && data.data.location.location_city ? '<div class="city">' + data.data.location.location_postal_code + ' ' + data.data.location.location_city + '</div>' : '') +
                  (data.data.location.location_description ? '<div class="description">' + data.data.location.location_description + '</div>' : '')
              },
              showEventTime: data.data.event.show_event_time,
              hideDateFlag: data.data.event_date_hide_date_flag,
            };
          }
          this.subject[identifier].next(this.detailDataStorage[identifier]);
        });

        promise.catch((reason: HttpErrorResponse) => {
          this.logService.createLog({
            message: 'loadDetailData: Could not load event',
            apiresponse: reason,
            eventid: identifier
          });
           console.log(reason);
           if (reason.error && reason.error.failure && reason.error.failure === 'failure') {
             if (reason.error.errors === 'Event not found.') {
               this.detailDataStorage[identifier].errorText = 'Veranstaltung nicht gefunden.';
               this.subject[identifier].next(this.detailDataStorage[identifier]);
             }
             if (reason.error.errors === 'partnershop not found.') {
               this.detailDataStorage[identifier].errorText = 'Keine Veranstaltungen verfügbar';
               this.subject[identifier].next(this.detailDataStorage[identifier]);
             }
             if (reason.error.errors === 'This partnershop does not exist.') {
               this.detailDataStorage[identifier].errorText = 'Kein Partnershop verfügbar';
               this.subject[identifier].next(this.detailDataStorage[identifier]);
             }
             if (reason.error.errors === 'Error connecting to gateway.') {
               this.detailDataStorage[identifier].errorText = 'Es ist ein Technischer Fehler aufgetreten';
               this.subject[identifier].next(this.detailDataStorage[identifier]);
             }
           }
        });
      }
    });

    return this.subject[identifier];
  }

  public hasDetailData(search: string): boolean {
    return this.detailDataStorage.hasOwnProperty(search);
  }
}
