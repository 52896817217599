import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {EtsListingData} from './ets-listing-data';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {EtsConfigService} from '../ets-config-service/ets-config.service';
import {take} from 'rxjs/operators';
import {formatNumber} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class EtsListingService {

  listingDataStorage: {[identifier: string]: EtsListingData};
  subject: {[identifier: string]: Subject<EtsListingData>};

  public constructor(@Inject('ETS_API_URL') public apiUrl: string, @Inject(LOCALE_ID) private locale: string, private http: HttpClient, private config: EtsConfigService) {
    this.listingDataStorage = {};
    this.subject = {};
  }

  public truncateString(input: string, length: number): string {
    if (input.length <= length) {
      return input;
    } else {
      for (let i = (length - 1); i < input.length; i++) {
        if (input.charAt(i) === ' ') {
          return input.substr(0, i + 1) + ' [...]';
        }
      }
      // Can't truncate
      return input;
    }
  }

  /**
   * Checks if more events are available than displayed in the list
   *
   * @param identifier string
   * @param pageSize number = 20
   * @returns boolean
   */
  public checkForMorePages(identifier: string): boolean {
    if (this.listingDataStorage.hasOwnProperty(identifier) && this.listingDataStorage[identifier].lastLoaded >= this.listingDataStorage[identifier].maxPage) {
      return false;
    }
    return true;
  }

  /**
   * Loads events into list
   *
   * @param identifier string
   * @param pageSize number = 20
   * @param filterText string = null
   * @param alwaysSearch boolean = false ~ Ignores existing data and starts a new search
   * @returns Subject<EtsListingData>
   */
  public loadListingData(identifier: string, pageSize: number = 20, filterText: string = null, filterMonth: string = null, alwaysSearch: boolean = false, filterEvents: string = null): Subject<EtsListingData> {
    if (this.listingDataStorage.hasOwnProperty(identifier) && this.listingDataStorage[identifier].lastLoaded >= this.listingDataStorage[identifier].maxPage && !alwaysSearch) {
      return this.subject[identifier];
    } else if (!this.listingDataStorage.hasOwnProperty(identifier)) {
      this.subject[identifier] = new Subject<EtsListingData>();
      this.listingDataStorage[identifier] = {maxPage: 0, lastLoaded: 0, events: []};
    }
    if (alwaysSearch) {
      this.listingDataStorage[identifier].events = [];
      this.listingDataStorage[identifier].lastLoaded = 0;
      this.listingDataStorage[identifier].maxPage = 0;
    }

    let eventFilter = '';
    if (filterEvents) {
      eventFilter = '&filter_event_date_ids=' + filterEvents;
    }

    const promise = this.http.get(this.apiUrl + 'events?page=' + (this.listingDataStorage[identifier].lastLoaded + 1)
      + '&pageSize=' + pageSize + ((filterText !== null) ? '&filter_text=' + filterText : '')
      + ((filterMonth !== null) ? '&filter_month=' + filterMonth : '' + eventFilter),
      {headers: {partnershopId: this.config.getShopIdentifier()}}).toPromise();

    promise.then((data: any) => {
      this.listingDataStorage[identifier].maxPage = data.last_page;
      this.listingDataStorage[identifier].lastLoaded = this.listingDataStorage[identifier].lastLoaded + 1;
      for (const eventDate of data.data.event_dates) {
        const event = data.data.events[eventDate.event_id];
        let location = data.data.locations[eventDate.location_id];

        const images = data.data.images['' + eventDate.event_id];
        let image = '';
        if (images) {
          for (const imageData of images) {
            if (imageData.type === 'medium') {
              image = imageData.url;
            }
          }
        }

        if (location === undefined) {
          location = {location_name : 'Diverse Veranstaltungsorte'};
        }

        let description = event.show_event_date_description ? eventDate.description : event.event_description;

        if (this.config.getConfigObject().misc.languageCode == 'en') {
          if (event.show_event_date_description) {
            description = eventDate.event_date_description_en != '' ? eventDate.event_date_description_en : eventDate.description;
          } else {
            description = event.event_description_en != '' ? event.event_description_en : eventDate.event_description;
          }
        }

        this.listingDataStorage[identifier].events.push({
          dateId: eventDate.event_date_id,
          printHome: eventDate.event_date_print_at_home_flag === 1,
          showEventTime: event.show_event_time,
          hideDateFlag: eventDate.event_date_hide_date_flag,
          image,
          date: eventDate.event_date_date_time,
          name: event.event_title_1,
          notBookable: eventDate.event_date_not_bookable,
          organizerId: eventDate.organizer_id,
          description: this.truncateString(description.replace(/<\s*\/?br\s*[\/]?>/gi, ' '), 120),
          location: location.location_name,
          prices: (eventDate.event_date_price_start === eventDate.event_date_price_end || eventDate.event_date_price_end === '0') ?
            formatNumber(eventDate.event_date_price_start, this.locale, '1.2-2') + ' €' :
            formatNumber(eventDate.event_date_price_start, this.locale, '1.2-2') + ' - ' + formatNumber(eventDate.event_date_price_end, this.locale, '1.2-2') + ' €',
          packages: eventDate.packages ? eventDate.packages : []
        });
      }
      this.subject[identifier].next(this.listingDataStorage[identifier]);
    });

    promise.catch(reason => {
      console.log(reason);
      if (reason.error && reason.error.status === 'failure') {
        if (reason.error.errors === 'partnershop not found.') {
          this.listingDataStorage[identifier].events = [];
          this.listingDataStorage[identifier].errorText = 'Keine Veranstaltungen verfügbar';
          this.subject[identifier].next(this.listingDataStorage[identifier]);
        }
        if (reason.error.errors === 'This partnershop does not exist.') {
          this.listingDataStorage[identifier].events = [];
          this.listingDataStorage[identifier].errorText = 'Kein Partnershop verfügbar';
          this.subject[identifier].next(this.listingDataStorage[identifier]);
        }
        if (reason.error.errors === 'Error connecting to gateway.') {
          this.listingDataStorage[identifier].events = [];
          this.listingDataStorage[identifier].errorText = 'Es ist ein Technischer Fehler aufgetreten';
          this.subject[identifier].next(this.listingDataStorage[identifier]);
        }
      }
    });

    return this.subject[identifier];
  }

  public searchEvents(filterText: string = null): Observable<any> {
    return this.http.get(this.apiUrl + 'search?' + ((filterText !== null) ? '&filter_text=' + filterText : ''), {headers: {partnershopId: this.config.getShopIdentifier()}}).pipe(
      take(1)
    );
  }
}
