<div *ngIf="loaded" class="headLiner clearfix">
  <!-- <button *ngIf="this.completeShop || config.getPreview()" (click)="back()" class="etsBtn detail">{{'btnBack' | translate}}</button> -->
  <div class="image basicSlider">
    <swiper [pagination]="{dynamicBullets:false, clickable:true}" [loop]="true">
      <ng-template *ngFor="let photo of details?.headliner?.photoUrls" swiperSlide>
          <img [src]="photo.url">
          <div class="infoOverlay">
            <div class="title">{{photo.title}}</div>
            <div class="copyright" *ngIf="photo.copyright !== ''">{{'ets-detail-headliner.Tickets' | translate}}: {{photo.copyright}}</div>
          </div>
      </ng-template>
    </swiper>
  </div>
  <div class="info">
    <div class="etsTitle">{{details.headliner.infoTitle}}</div>
    <div class="subTitle">{{details.headliner.infoSubTitle}}</div>
    <div class="date" *ngIf="!details?.hideDateFlag && !isPackage && !details.showEventTime">{{details.headliner.infoDateString.replace(' ', 'T') | date:'EEEE, dd.MM.yyyy, H:mm':undefined:(globalConfig.misc.languageCode !== 'de') ? 'en' : 'de'}} {{'ets-detail-headliner.Time' | translate}}</div>
    <div class="date" *ngIf="!details?.hideDateFlag && !isPackage && details.showEventTime">{{details.headliner.infoDateString.replace(' ', 'T') | date:'EEEE, dd.MM.yyyy':undefined:(globalConfig.misc.languageCode !== 'de') ? 'en' : 'de'}}</div>
    <div class="location">{{details.headliner.infoLocationString}}</div>

    <!-- <a class="etsBtn ticket etsfont-ticket-button-detail" (click)="scrollToTickets()"><span>{{'ets-detail-headliner.Tickets' | translate}}</span></a> -->
    <div class="ets-ticket-wrapper ets-ticket-wrapper-detail" (click)="scrollToTickets()">
      <svg class="ets-ticket-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1799.36 557.58"><path class="ets-ticket-path" d="m1728.05,454.87c0-39.4,31.92-71.35,71.31-71.39v-32.91c-39.43,0-71.39-31.96-71.39-71.39s31.96-71.39,71.39-71.39v-33.69c-39.37-.07-71.26-32-71.26-71.39s31.89-71.32,71.26-71.39V0H0v31.32s.04,0,.07,0c39.43,0,71.39,31.96,71.39,71.39S39.49,174.11.07,174.11c-.02,0-.04,0-.07,0v33.69c39.4.04,71.33,31.98,71.33,71.39S39.4,350.54,0,350.58v32.91s0,0,.01,0c39.43,0,71.39,31.96,71.39,71.39S39.44,526.26.01,526.26c0,0,0,0-.01,0v31.32h1799.36v-31.32c-39.39-.04-71.31-31.99-71.31-71.39Z" fill="#f2001c"/></svg>
      <div>{{'ets-detail-headliner.Tickets' | translate}}</div>
    </div>
    <div *ngIf="globalConfig.ticketing.showPriceInHeadliner && details.headliner.infoPriceBegin == details.headliner.infoPriceEnd && !isPackage" class="prices">{{'ets-detail-headliner.eventPricing' | translate }}: {{details.headliner.infoPriceBegin | currency}} EUR</div>
    <div *ngIf="globalConfig.ticketing.showPriceInHeadliner && details.headliner.infoPriceBegin != details.headliner.infoPriceEnd" class="prices">{{'ets-detail-headliner.eventPricing' | translate }}: {{details.headliner.infoPriceBegin | currency}} EUR — {{details.headliner.infoPriceEnd | currency}} EUR</div>
    <div class="packageButton" *ngIf="globalConfig.ticketing.showPackages && details.headliner.packages && details.headliner.packages.length > 0">
      <a *ngFor="let package of details?.headliner?.packages" class="link etsBtn secondary" [appEtsRouterLink]="[{name: embedName != 'subComponent' ? embedName : 'Page', target: 'detail-777' + package.package_id}]">{{package.price_text}}</a>
    </div>

    <ng-container >
      <div>

      </div>
    </ng-container>
  </div>
  <div *ngIf="externalContent" class="headliner video">
    <iframe [hidden]="videoLink?.length <= 0" [src]="videoLink | safe" width="560" height="315" title="Youtube video player" [frameBorder]="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</div>
<div *ngIf="details" class="shortDescription" [innerHTML]="eventDescription"></div>
<a class="headlinerInfoLink" *ngIf="details && details.headliner.eventLinkText?.length > 0" [href]="details.headliner.eventLink" target="{{details.headliner.eventLinkTarget}}">{{details.headliner.eventLinkText}}</a>
<div *ngIf="isPackage" class="eventDateList ticketBox">
  <h4>{{'ets-detail-headliner.eventPackage' | translate}}</h4>
  <div class="flexTable listTable eventList">
    <div class="content">
      <div class="line" *ngFor="let packageEvent of details.events">
        <div class="date">
          @if(!packageEvent.showEventTime) {
            {{packageEvent.eventDate.replace(' ', 'T') | date:'EEEE, dd.MM.yyyy, H:mm':undefined:(globalConfig.misc.languageCode !== 'de') ? 'en' : 'de'}} {{'ets-detail-headliner.Time' | translate}}
          } @else {
            {{packageEvent.eventDate.replace(' ', 'T') | date:'EEEE, dd.MM.yyyy':undefined:(globalConfig.misc.languageCode !== 'de') ? 'en' : 'de'}}
          }
        </div>
        <div class="locationName">{{packageEvent.eventLocation}}</div>
      </div>
    </div>
  </div>
</div>
