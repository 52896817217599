import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../base-component/base-component';
import {EtsLoginService} from '../ets-login-service/ets-login-service';
import {EtsBasketService} from '../ets-basket-service/ets-basket-service';
import {EtsCheckoutService} from '../ets-checkout-service/ets-checkout-service';
import {EtsConfigService} from '../ets-config-service/ets-config.service';
import {
  EtsFormDataRegister,
  EtsPersonalisation,
  EtsRegisterDataErrors,
} from '../ets-checkout-service/ets-checkout-data';
import { BehaviorSubject, forkJoin, Subscription} from 'rxjs';
import {EtsLoginData} from '../ets-login-service/ets-login-data';
import {switchMap, take} from 'rxjs/operators';
import {EtsComponentRouterService} from '../ets-component-router-service/ets-component-router.service';
import {ToastrService} from 'ngx-toastr';
import {EtsChosenShipping} from '../ets-basket-service/ets-basket-data';
import {NgxSpinnerService} from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { EtsCouponService } from '../ets-coupon-service/ets-coupon-service';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SpecialrequestsService } from '../services/specialrequests.service';
import { ErrorcodeService } from '../helpers/errorcode-service.service';
import { LogService } from '../services/LogService.service';

@Component({
  selector: 'app-ets-checkout',
  templateUrl: './ets-checkout.component.html',
  styleUrls: ['./ets-checkout.component.css']
})
export class EtsCheckoutComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('paymentList') paymentList: ElementRef<HTMLElement>;
  @Input() set setStep(step: string) {
    setTimeout(() => {
      if (!this.boschShop)  {
        setTimeout(() => {
          if (document.getElementById('checkoutCaption')) {
            document.getElementById('checkoutCaption').scrollIntoView({behavior: 'smooth', block: 'start'});
          }
        });
      } else {
        setTimeout(() => {
          if (document.querySelector('#navbar')) {
            window.scrollTo({top : 0});
            document.getElementById('navbar').scrollIntoView(true);
          }
        },100);
      }
    });


    this.checkoutService.getPaymentDataSubjectValue.paymentMethods = [];
    this.paymentMethodsLoaded = false;
    this.step = '';
    this.stepInt = 0;
    if (step) {

      if (!this.specialRequestService.checkBasketItems()) {
        this.router.navigateEmbed([{name: this.embeddedIn, target: 'basket-details'}]);
        return;
      }
      this.step = step;
      if (step === 'finish' && localStorage.getItem('ets-basket-' + this.config.getShopIdentifier())) {
        this.spinner.show('finishspinner');
        this.stepInt = 10;
        this.config.getShopIdentifierSubject().subscribe(() => {
          if (!this.loadingFinishInProgress) {
            this.loadingFinishInProgress = true;
            let showPrintAtHome = true;
            // * Hide PrintAtHome Button for GuestOrders.
            if (localStorage.getItem('ets-isGuestOrder-' + this.config.getShopIdentifier())) {
              showPrintAtHome = false;
            }
            this.checkoutService.checkPaymentSuccessful().subscribe(
              () => {

                this.checkoutService.getPaymentData().subscribe(
                  paymentData => {
                    if (paymentData) {
                      if (paymentData.data.sessionData.shippingMethod.name.toLowerCase().indexOf('print@home') >= 0) {
                        if (showPrintAtHome) {
                          this.isPrintAtHomeOrder = true;
                        }
                      }
                    }
                    this.spinner.hide('finishspinner');
                  },
                  error => {
                    this.logService.createLog({
                      message: 'CheckoutComponent Step finish getPaymentData: Could not retrieve paymentdata',
                      apiresponse: error
                    });
                    console.log(error);
                    this.spinner.hide('finishspinner');
                  }
                );
                this.loadingFinishInProgress = false;
                this.checkoutService.orderSuccessful();
                if (localStorage.getItem('ets-isGuestOrder-' + this.config.getShopIdentifier())) {
                  this.loginService.doLogout().subscribe(
                  );
                  this.isGuestOrder = false;
                  this.wasAlreadyLoggedIn = false;
                }
              },
              error => {
                console.log(error);
                this.logService.createLog({
                  message: 'CheckoutComponent Step finish checkPaymentSuccessfull: Error occured while payment',
                  apiresponse: error
                });
                this.loadingFinishInProgress = false;
                this.spinner.hide('finishspinner');
                this.toastr.error(this.translate.instant('toastr.checkout.payError'));
              }
            );
          }
        });
      } else if ((step === 'address' || step === 'register') && (this.etsRegisterData === undefined || this.etsRegisterData.salutations.length === 0)) {
        setTimeout(() => {
          this.stepInt = 1;
          this.basketService.basketLoading.next(true);

          // * Update isGuestOrder for address form.
          this.config.getShopIdentifierSubject().subscribe(identifier => {
            this.isGuestOrder = localStorage.getItem('ets-isGuestOrder-' + identifier) == '1' ? true : false;
          });


          this.config.getShopIdentifierSubject().subscribe((identifier: string) => {
            if (identifier !== '') {
              forkJoin({
                register: this.checkoutService.loadFormDataRegister(),
              }).subscribe(data => {
                  this.basketService.basketLoading.next(false);
                  this.etsRegisterData = data.register;
                  this.loaded = true;
                  this.loadingInProgress = false;
                },
                error => {
                  this.logService.createLog({
                    message: 'CheckoutComponent Step: address|register: Could not load formdata register',
                    apiresponse: error
                  });
                  console.log(error);
                  this.toastr.error(this.translate.instant('toastr.checkout.addressLoadingError'));
                  this.basketService.basketLoading.next(false);
                });
            }
          });
        });
      } else if (step === 'shipping') {
        setTimeout(() => {
          this.stepInt = 2;
          this.basketService.basketLoading.next(true);
          this.config.getShopIdentifierSubject().subscribe((identifier: string) => {
            if (identifier !== '') {
              forkJoin({
                shipping: this.checkoutService.loadShippingMethods(),
              }).subscribe( () => {
                  this.basketService.basketLoading.next(false);
                  this.loaded = true;
                  this.loadingInProgress = false;
                },
                error => {
                  this.logService.createLog({
                    message: 'CheckoutComponent Step: shipping: Could not load shipping methods',
                    apiresponse: error
                  });
                  console.log(error);
                  this.toastr.error(this.translate.instant('toastr.checkout.shippingLoadingError'));
                  this.basketService.basketLoading.next(false);
                });
            }
          });
        });
      } else if (step === 'payment') {
        setTimeout(() => {
          this.stepInt = 6;
          this.basketService.basketLoading.next(true);
          this.config.getShopIdentifierSubject().subscribe((identifier: string) => {
            if (identifier !== '') {
              forkJoin({
                payment: this.checkoutService.loadPaymentMethod(),
              }).subscribe( () => {
                  this.basketService.basketLoading.next(false);
                  this.loaded = true;
                  this.loadingInProgress = false;
                },
                error => {
                  this.logService.createLog({
                    message: 'CheckoutComponent Step: payment: Could not load payment methods',
                    apiresponse: error
                  });
                  console.log(error);
                  this.toastr.error(this.translate.instant('toastr.checkout.payInfoLoadingError'));
                  this.basketService.basketLoading.next(false);
                });
            }
          });
        });
      } else if (step === 'confirm') {
        this.basketService.basketLoading.next(true);
        this.checkoutService.checkForShippingMethod().subscribe( data => {
          if (data) {
            this.stepInt = 7;
            this.config.getShopIdentifierSubject().subscribe((identifier: string) => {
              if (identifier !== '') {
                forkJoin({
                  telecash: this.checkoutService.getTelecashData(),
                  agb: this.checkoutService.getAgb(),
                }).subscribe( () => {
                    this.basketService.basketLoading.next(false);
                    this.loaded = true;
                    this.loadingInProgress = false;
                  },
                  error => {
                    this.logService.createLog({
                      message: 'CheckoutComponent Step: confirm: Could not load telecashdata|agbdata',
                      apiresponse: error
                    });
                    console.log(error);
                    this.toastr.error(this.translate.instant('toastr.checkout.generalError'));
                    this.basketService.basketLoading.next(false);
                  });
              }
            });
          } else {
            this.basketService.basketLoading.next(false);
            this.toastr.error(this.translate.instant('errorMessage.checkout.noShippingMethodSetError'));
            this.router.navigateEmbed([{name: this.embeddedIn, target: 'checkout-shipping'}]);
          }
        });

      } else if (step === 'personalisation') {
        setTimeout(() => {
          this.stepInt = 5;
          this.basketService.basketLoading.next(true);
          this.config.getShopIdentifierSubject().subscribe((identifier: string) => {
            if (identifier !== '') {
              forkJoin({
                personal: this.checkoutService.loadPersonalisationData(),
              }).subscribe(data => {
                  this.personalisationData = data.personal;
                  this.basketService.basketLoading.next(false);
                  this.loaded = true;
                  this.loadingInProgress = false;
                },
                error => {
                  this.logService.createLog({
                    message: 'CheckoutComponent Step: personlization: Could not load personlization informations',
                    apiresponse: error
                  });
                  console.log(error);
                  this.toastr.error(this.translate.instant('toastr.checkout.generalError'));
                  this.basketService.basketLoading.next(false);
                });
            }
          });
        });
      } else if (step === 'login') {
        setTimeout(() => {
          if (this.isRegularUser) {
            this.router.navigateEmbed([{name: this.embeddedIn, target: 'checkout-address'}]);
          }  else if (!((localStorage.getItem('ets-isGuestOrder-' + this.config.getShopIdentifier()) !== null
              && localStorage.getItem('ets-isGuestOrder-' + this.config.getShopIdentifier()) !== undefined
              && localStorage.getItem('ets-isGuestOrder-' + this.config.getShopIdentifier()) === '1')
              && !this.isRegularUser)) {
            if (!sessionStorage.getItem('ets-isTelecashGuestCheckout-' + this.config.getShopIdentifier())) {
              this.router.navigateEmbed([{name: this.embeddedIn, target: 'checkout-login'}]);
            }
          }
        });
      } else if (step === 'passwordrecovery') {
        this.router.navigateEmbed([{name: this.embeddedIn, target: 'checkout-passwordrecovery'}]);
      }
    }
  }
  @Input() embeddedIn: string;

  loadFirst: boolean;
  loaded: boolean;
  allowAGB: boolean;
  showAGBError: boolean;
  loadingInProgress: boolean;
  loadingFinishInProgress: boolean;
  etsRegisterData: EtsFormDataRegister;
  loginPassword: string;
  loginUser: string;
  step: string;
  stepInt: number;
  loggedInSubscription: Subscription;
  wasAlreadyLoggedIn: boolean;
  showPasswordForm: boolean;
  isCustomer: boolean;
  isRegularUser: boolean;
  isGuestOrder: boolean;
  guestOrdersEnabled: boolean;
  hideCheckoutBar: boolean;
  partner_id: number;
  addInfoMaxLength: boolean;
  passwordEmail: string;
  password: string;
  passwordRepeat: string;
  isPrintAtHomeOrder: boolean;
  printAtHomeLoading: boolean;
  passwordRecoveryLoading: boolean;

  showeventTime: any;

  registerShowPassword: boolean;
  registerShowPasswordConf: boolean;

  addressShowOldPassword: boolean;
  addressShowPassword: boolean;
  addressShowPasswordConf: boolean;

  shippingMethod: number;
  paymentMethod: string;
  voucherCodeRedeemed: boolean = false;



  promotionCode: string;
  couponCode: string;
  iban: string;
  bic: string;

  addInfoDesc: string;
  addInfoReq: boolean;

  // ! BEG: Special properties for bosch.
  boschShop: boolean = false;
  meetAndGreet: boolean = false;
  photoConsent: boolean = false;

  // ! END: Special properties for bosch.

  personalisationData: EtsPersonalisation[];

  accountData: EtsLoginData;

  registerErrors: EtsRegisterDataErrors;

  partnerSettings: any;

  paymentMethodsLoaded: boolean;

  globalConfig: any;

  promotionCodeForm: FormGroup = new FormGroup({
    promotioncode: new FormControl(''),
  });

  constructor(
    public loginService: EtsLoginService,
    public basketService: EtsBasketService,
    public checkoutService: EtsCheckoutService,
    public config: EtsConfigService,
    public router: EtsComponentRouterService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    public location: Location,
    private couponService: EtsCouponService,
    private formBuilder: FormBuilder,
    private specialRequestService: SpecialrequestsService,
    private errorCodeService: ErrorcodeService,
    private logService: LogService
    // private changeDetector: ChangeDetectorRef
  ) {
    super();
    this.addInfoMaxLength = false;
    this.shippingMethod = 0;
    this.promotionCodeForm = this.formBuilder.group({
      promotioncode: ['', [Validators.minLength(4), Validators.maxLength(30)]]
    });
  }

  ngOnInit(): void {
    this.initStandardValues();

    if (localStorage.getItem('ets-isGuestOrder-' + this.config.getShopIdentifier()) !== null && localStorage.getItem('ets-isGuestOrder-' + this.config.getShopIdentifier()) !== undefined) {
      this.isCustomer = (localStorage.getItem('ets-isGuestOrder-' + this.config.getShopIdentifier()) === '1') ? true : false;
      this.isGuestOrder = (localStorage.getItem('ets-isGuestOrder- ' + this.config.getShopIdentifier()) === '1') ? true : false;
    }

    this.isGuestOrder = localStorage.getItem('ets-isGuestOrder- ' + this.config.getShopIdentifier()) === '1' ? true : false;
    this.config.getShopIdentifierSubject().subscribe((identifier: string) => {
      this.showeventTime = JSON.parse(localStorage.getItem('ets-eventTime-' + this.config.getShopIdentifier()));
      if (identifier == null || identifier === '') {
        return;
      }

      switch(this.config.getShopIdentifier()) {
        case '8284e02215089cb7d8cf019e5697c390':
        case '53a8edde17f2780485ccbdf81eb74e64':
        case 'f182d9ad06e9bda677d7a3046a0dc7ab':
        case '5306f1fd3425bbd1907c34d28fa82da3':
        case 'e26ba42994bef547cee9e624bb48dfe6':
          this.boschShop = true;
          break;
      }

      // ! This is only for Bosch Shop => Need to remove it later
      if (this.config.getShopIdentifier() === 'e785beecc5448bc15507d5986855df4b') {
        this.addInfoMaxLength = true;
      }
      this.addInfoDesc = this.config.getConfigObject().addressfield.addInfoDesc;
      this.addInfoReq = this.config.getConfigObject().addressfield.addInfoReq;
      this.guestOrdersEnabled = this.config.getConfigObject().misc.webshopGuestOrders;

      if (localStorage.getItem('ets-login-token-' + this.config.getShopIdentifier()) !== null
        && localStorage.getItem('ets-login-token-' + this.config.getShopIdentifier()) !== undefined)
      {
        this.isRegularUser = true;
      }

      this.config.getPartnerSettings(identifier).subscribe((settings: any) => {
        this.partnerSettings = settings.data;
        if (this.isRegularUser && this.step === 'login') {
          this.router.navigateEmbed([{name: this.embeddedIn, target: 'checkout-address'}]);
        }

        this.checkLoginState();
        this.reloadCheckoutInformations();

        if (this.step === 'confirm') {
          this.loadCheckoutConfirm();
        }

        if (this.step === 'finish') {
          this.processCheckoutFinishProcedure();
        }
      });
    });
  }

  /**
   * Reloads checkout informations like shipping method and paymentmethod
   *
   * @returns void
   */
  private reloadCheckoutInformations(): void {
    this.partner_id = this.config.getConfigObject().misc.partner_id;
    this.checkoutService.reloadPaymentMethod();
    this.checkoutService.reloadShippingMethod();
    this.shippingMethod = this.checkoutService.getShippingSubjectValue.id;
    this.paymentMethod = this.checkoutService.getPaymentSubjectValue.id;
  }

  /**
   * Load checkout confirm tab informations like agb and telecashdata
   *
   * @returns void
   */
  private loadCheckoutConfirm(): void {
    this.spinner.show('checkoutConfirmLoading');
    this.checkoutService.getAgb().subscribe();
    this.checkoutService.getTelecashData().subscribe( () => {
      this.spinner.hide('checkoutConfirmLoading');
    },
    error => {
      console.log(error);
      this.logService.createLog({
        message: 'Could not load AGB and telecashdata',
        apiresponse: error
      });
      this.spinner.hide('checkoutConfirmLoading');
    });
  }

  /**
   * Checks login state of customer
   *
   * @returns void
   */
  private checkLoginState(): void {
    this.loggedInSubscription = this.loginService.isLoggedIn.subscribe((loginState) => {
      if (loginState === false && this.step !== 'promotionCode' && this.step !== 'register') {
        this.isRegularUser = false;
        this.isGuestOrder = false;

        if (!sessionStorage.getItem('ets-isTelecashGuestCheckout-' + this.config.getShopIdentifier())) {
          if (this.step !== 'passwordrecovery' && this.step !== 'finish' && this.step !== 'password') {
            this.setStep = 'login';
          }

        }

        this.wasAlreadyLoggedIn = false;
      } else if (loginState === true) {
        this.wasAlreadyLoggedIn = true;
      }
    });
  }

  /**
   * Initializes member variables with their standard value
   *
   * @returns void
   */
  private initStandardValues(): void {
    this.globalConfig = this.config.getConfigObject();
    this.addInfoMaxLength = false;
    this.loadFirst = true;
    this.loaded = false;
    this.loadingInProgress = true;
    this.loadingFinishInProgress = false;
    this.allowAGB = false;
    this.showAGBError = false;
    this.shippingMethod = 0;
    this.etsRegisterData = new EtsFormDataRegister();
    this.registerErrors = new EtsRegisterDataErrors();
    this.etsRegisterData.salutations = [];
    this.personalisationData = [];
    this.etsRegisterData.county = [];
    this.iban = null;
    this.bic = null;
    this.couponCode = null;
    this.wasAlreadyLoggedIn = false;
    this.partnerSettings = {};
    this.isGuestOrder = false;
    this.isCustomer = false;
    this.isRegularUser = false;
    this.guestOrdersEnabled = false;
    this.paymentMethodsLoaded = false;
    this.isPrintAtHomeOrder = false;
    this.boschShop = false;
    this.addInfoDesc = '';
    this.addInfoReq = false;

  }

  /**
   * Checks if payment was successfull, enables p@h ticket download if selected
   *
   * @returns void
   */
  private processCheckoutFinishProcedure(): void {
    this.spinner.show('finishspinner');
    if (!this.loadingFinishInProgress) {
      this.loadingFinishInProgress = true;
      this.checkoutService.checkPaymentSuccessful().subscribe(
        () => {
          let showPrintAtHome = true;
          // * Hide PrintAtHome Button for GuestOrders.
          if (localStorage.getItem('ets-isGuestOrder-' + this.config.getShopIdentifier())) {
            showPrintAtHome = false;
          }
          this.checkoutService.getPaymentData().subscribe(
            paymentData => {
              if (paymentData) {
                if (paymentData.data.sessionData.shippingMethod.name.toLowerCase().indexOf('print@home') >= 0) {
                  if (showPrintAtHome) {
                    this.isPrintAtHomeOrder = true;
                  }
                }
              }
              this.spinner.hide('finishspinner');
            },
            error => {
              console.log(error);
              this.spinner.hide('finishspinner');
            }
          );
          this.loadingFinishInProgress = false;
          this.checkoutService.orderSuccessful();
        },
        error => {
          this.loadingFinishInProgress = false;
          this.spinner.hide('finishspinner');
          let errorMessage = this.checkoutService.getCheckoutError(error);
          this.logService.createLog({
            message: errorMessage,
            apiresponse: error
          });
          this.toastr.error(this.translate.instant(errorMessage),'',{disableTimeOut: true});

          // * Unauthorized user => return to homepage.
          if (error.status === 401) {
            this.router.navigateEmbed([{name: this.embeddedIn, target: ''}]);
          }
        }
      );
    }
  }

  ngOnDestroy(): void {
    if (this.loggedInSubscription) {
      this.loggedInSubscription.unsubscribe();
    }
    this.shippingMethod = null;
  }

  ngAfterViewInit(): void {
    if (this.step === 'finish') {
      this.spinner.show('finishspinner');
    }
  }

  /**
   * Triggers HTML elements click function if element is in active state.
   *
   * @returns void
   */
  renderSelectedPaymentMethod(): void {
    if (! this.paymentMethodsLoaded && this.step === 'payment') {
      if ((document.querySelector('.paymentMethod .active > div')) === null) {
        return;
      }

      const activeElement = (document.querySelector('.paymentMethod .active > div') as HTMLElement);

      if (activeElement !== null && activeElement !== undefined) {
        activeElement.click();
      }

      this.paymentMethodsLoaded = true;
    }
  }

  /**
   * Login for regular user
   *
   * @returns void
   */
  doLogin(): void {
    this.basketService.basketLoading.next(true);
    if (this.loginPassword.length > 0 && this.loginUser.length > 0) {
      this.loginService.doLogin(this.loginUser.toLowerCase(), this.loginPassword).subscribe(
        () => {
          this.basketService.basketLoading.next(false);
          this.isRegularUser = true;
          this.isGuestOrder = false;
          if (sessionStorage.getItem('ets-promotionCode-' + this.config.getShopIdentifier())) {
            this.couponService.setCouponCode(this.promotionCode, localStorage.getItem('ets-token-' + this.config.getShopIdentifier())).subscribe(
              () => {
              },
              error => {
                console.log(error);
              }
            );
          }
          this.router.navigateEmbed([{name: this.embeddedIn, target: 'checkout-address'}]);
      },
      error => {
          console.log(error);
          let errorMessage = ''
          if (error.status === 403) {
            errorMessage = this.translate.instant('toastr.login.errorBlocked');
          } else {
            errorMessage = this.translate.instant('toastr.login.error');
          }
          this.toastr.error(errorMessage);
          this.logService.createLog({
            message: errorMessage,
            apiresponse: error,
            user: this.loginUser
          });
          this.basketService.basketLoading.next(false);
      });
    } else {
      this.toastr.error(this.translate.instant('ets-menu-buttons.login.errorEmptyFields'));
      this.basketService.basketLoading.next(false);
    }
  }

  /**
   * Login as guest user
   *
   * @returns void
   */
  doGuestLogin(): void {

    if (this.basketService.basketSubjectValue.items.length === 0) {
      this.toastr.error(this.translate.instant('toastr.login.guestOrderEmptyBasket'));
      return;
    }
    this.basketService.basketLoading.next(true);

    this.loginService.doGuestLogin().subscribe(
      () => {
        this.isRegularUser = false;
        this.basketService.basketLoading.next(false);
        this.isGuestOrder = true;
        if (sessionStorage.getItem('ets-promotionCode-' + this.config.getShopIdentifier())) {
          this.couponService.setCouponCode(this.promotionCode, this.loginService.loginTokenValue).subscribe(
            () => {},
            error => {
              console.log(error);
            }
          );
        }
        this.isCustomer = (JSON.parse(localStorage.getItem('ets-login-account-' + this.config.getShopIdentifier())).guestLogin === 'J') ? false : true;
        this.router.navigateEmbed([{name: this.embeddedIn, target: 'checkout-address'}]);
    },
    error => {
        console.log(error);
        this.logService.createLog({
          message: 'Guestlogin failed.'
        });
        this.toastr.error(this.translate.instant('toastr.login.error'));
        this.basketService.basketLoading.next(false);
    });

  }

  /**
   * Updates customer informations
   *
   * @param $event any
   * @returns void
   */
  updateAddress($event): void {
    this.basketService.basketLoading.next(true);
    this.registerErrors = new EtsRegisterDataErrors();
    const newAddress = this.loginService.loginDataValue;

    newAddress.salutation = $event.target.salutation.value;
    newAddress.title = $event.target.title.value;
    newAddress.forename = $event.target.firstname.value;
    newAddress.surname = $event.target.lastname.value;
    newAddress.nameSuffix = $event.target.nameAddition.value;
    newAddress.street = $event.target.streetName.value;
    newAddress.houseNumber = $event.target.streetNumber.value;
    newAddress.zipCode = $event.target.zip.value;
    newAddress.city = $event.target.city.value;
    newAddress.countryInternalKey = $event.target.country.value;
    newAddress.phoneAreaCode = $event.target.areaCode.value;
    newAddress.phoneNumber = $event.target.phoneNumber.value;
    newAddress.emailAddress = $event.target.email.value ?? '';
    newAddress.emailAddressRepeat = $event.target.email.value ?? '';

    if (this.isGuestOrder) {
      newAddress.emailAddressRepeat = $event.target.emailRepeat.value ?? '';
    }

    if (this.isCustomer && this.showPasswordForm) {

      newAddress.passwordOld = $event.target.passwordOld.value ?? '';
      newAddress.password = $event.target.password.value ?? '';
      newAddress.passwordRepeat = $event.target.passwordRepeat.value ?? '';
    }

    if (newAddress.emailAddress && newAddress.emailAddressRepeat) {
      newAddress.emailAddress = newAddress.emailAddress.trim();
      newAddress.emailAddressRepeat = newAddress.emailAddressRepeat.trim();
    }

    if (newAddress.passwordOld && newAddress.password && newAddress.passwordRepeat) {
      newAddress.passwordOld = newAddress.passwordOld.trim();
      newAddress.password = newAddress.password.trim();
      newAddress.passwordRepeat = newAddress.passwordRepeat.trim();
    }

    let err = false;
    if (newAddress.salutation === undefined || newAddress.salutation === null || $event.target.salutation.value === '') {
      this.registerErrors.salutation = this.translate.instant('errorMessage.register.salutationError');
      err = true;
    }
    if (newAddress.forename === '' || newAddress.forename === null || newAddress.forename === 'undefined') {
      this.registerErrors.forename = this.translate.instant('errorMessage.register.forenameError');
      err = true;
    }
    if (newAddress.surname === '' || newAddress.surname === null || newAddress.surname === 'undefined') {
      this.registerErrors.surname = this.translate.instant('errorMessage.register.surnameError');
      err = true;
    }
    if (newAddress.street === '' || newAddress.street === null || newAddress.street === 'undefined') {
      this.registerErrors.street = this.translate.instant('errorMessage.register.streetError');
      err = true;
    }
    if (newAddress.houseNumber === '' || newAddress.houseNumber === null || newAddress.houseNumber === 'undefined') {
      this.registerErrors.houseNumber = this.translate.instant('errorMessage.register.housenumberError');
      err = true;
    }
    if (newAddress.zipCode === '' || newAddress.zipCode === null || newAddress.zipCode === 'undefined') {
      this.registerErrors.zipCode = this.translate.instant('errorMessage.register.zipCodeError');
      err = true;
    }
    if (newAddress.city === '' || newAddress.city === null || newAddress.city === 'undefined') {
      this.registerErrors.city = this.translate.instant('errorMessage.register.cityError');
      err = true;
    }
    if (newAddress.emailAddress === '' || newAddress.emailAddress === null || newAddress.emailAddress === 'undefined') {
      this.registerErrors.emailAddress = this.translate.instant('errorMessage.register.emailError');
      err = true;
    }
    if (newAddress.emailAddressRepeat === '' || newAddress.emailAddressRepeat === null || newAddress.emailAddressRepeat === 'undefined') {
      this.registerErrors.emailAddressRepeat = this.translate.instant('errorMessage.register.emailError');
      err = true;
    }
    if (newAddress.password !== newAddress.passwordRepeat) {
      this.registerErrors.password = this.translate.instant('errorMessage.register.passwordRepeatError');
      err = true;
    }
    if (newAddress.countryInternalKey === null || newAddress.countryInternalKey === undefined || $event.target.country.value === '') {
      this.registerErrors.country = this.translate.instant('errorMessage.register.countryError');
      err = true;
    }

    // * Validate email address and email repeat by GuestOrders
    if (this.isGuestOrder) {
      if (newAddress.emailAddress.trim() !== newAddress.emailAddressRepeat.trim()) {
        this.registerErrors.emailAddressRepeat = this.translate.instant('errorMessage.register.emailRepeatError');
        err = true
      }
    }

    if (this.addInfoReq) {
      if (newAddress.nameSuffix === '') {

        if (this.addInfoDesc !== '') {
          this.registerErrors.additionalInfo = 'Bitte füllen Sie das Feld: ' + this.addInfoDesc + ' aus.';
          this.toastr.error('Bitte füllen Sie das Feld: ' + this.addInfoDesc + ' aus.');
        } else {
          this.registerErrors.additionalInfo = 'Bitte füllen Sie das Feld "Zusatzinformationen" aus.';
          this.toastr.error('Bitte füllen Sie das Feld "Zusatzinformationen" aus.');
        }

        err = true;
      }
    }

    if (this.partner_id === 333763) {
      if (newAddress.nameSuffix === '') {
        this.toastr.error('Bitte geben Sie Ihre Porsche-Mitarbeiternummer ein.');
        err = true;
      }
    }

    switch (this.config.getShopIdentifier()) {
      case '8284e02215089cb7d8cf019e5697c390':
      case '53a8edde17f2780485ccbdf81eb74e64':
      case 'f182d9ad06e9bda677d7a3046a0dc7ab':
      case '5306f1fd3425bbd1907c34d28fa82da3':
      case 'e26ba42994bef547cee9e624bb48dfe6':
        let department = $event.target.department.value ?? '';
        let company = $event.target.company.value ?? '';

        if (department === '') {
          this.registerErrors.department = 'Bitte geben Sie eine Abteilung an.';
          this.toastr.error('Das Feld Abteilung darf nicht leer sein');

        }
        if (company === '') {
          this.registerErrors.company = 'Bitte geben Sie eine Firma an.';
          this.toastr.error('Das Feld Firma darf nicht leer sein');
        }

        if (company === '' || department === '') {
          this.basketService.basketLoading.next(false);
          return;
        }

        let name4 = company + ' / ' + department;

        if (name4.length > 60) {
          this.toastr.error('Die Felder Abteilung und Firma dürfen zusammen nicht länger als 60 Zeichen sein.');
          this.basketService.basketLoading.next(false);
          return;
        }
        newAddress.name4 = name4;
        break;

      default:
        newAddress.name4 = '';
        break;
    }

    if (err) {
      this.basketService.basketLoading.next(false);
      return;
    }

    this.loginService.updateCustomer(newAddress).subscribe(
      data => {
        this.basketService.basketLoading.next(false);
        if (data) {
          this.router.navigateEmbed([{name: this.embeddedIn, target: 'checkout-shipping'}]);
        }
      },
      error => {
        console.log(error);
        this.logService.createLog({
          message: 'Could not update customer informations',
          apiresponse: error
        });
        if (error.status === 401) {
          this.toastr.error(this.translate.instant('errorMessage.checkout.sessionTimeoutError'));
          setTimeout(() => {
            this.checkoutService.clearLocalStorage();
            this.router.resetToHome();
          }, 2000);
        } else if (error.status === 404) {

          switch (error.error.errorCode) {
            case 'r9BTP':
              this.toastr.error(this.translate.instant('errorMessage.checkout.sessionTimeoutError'));
              setTimeout(() => {
                this.checkoutService.clearLocalStorage();
                this.router.resetToHome();
              }, 2000);
              break;
            case 'P16101':
              this.toastr.error(this.translate.instant('toastr.porsche.invalidNumber'));
              break;
            case 'P16102':
              this.toastr.error(this.translate.instant('toastr.porsche.numberNotFound'));
              break;

            default:
              this.toastr.error('Unbekannter Fehler');
              break;
          }

        } else if (error.status === 409) {
          if (error.error.errorCode === 'cust409') {
            this.toastr.error(this.translate.instant('errorMessage.checkout.invalidEmailAddress'))
          } else {
            this.toastr.error(this.translate.instant('errorMessage.checkout.emailAlreadyExistsError'));
          }
          this.basketService.basketLoading.next(false);
        } else {
          this.toastr.error(this.translate.instant('toastr.contact.error'));
          this.basketService.basketLoading.next(false);
        }
      }
    );
  }

  /**
   * Sets selected shipping method to order
   *
   * @param navigate boolean
   * @returns void
   */
  setShippingMethod(navigate: boolean = true): void {

    this.basketService.basketLoading.next(true);
    if (this.shippingMethod !== 0) {
      this.checkoutService.setShippingMethod(this.shippingMethod).pipe(
        take(1),
        switchMap(data => {
          if (data) {
            return this.basketService.updateBasket();
          } else {
            return new BehaviorSubject<boolean>(false).asObservable();
          }
        }),
      ).subscribe(
        () => {
          this.basketService.setShippingToOrder(this.checkoutService.getShippingSubjectValue);
          this.basketService.basketLoading.next(false);

          if (navigate) {
            if (this.checkoutService.getShippingDataSubjectValue.personalisation) {
              this.router.navigateEmbed([{name: this.embeddedIn, target: 'checkout-personalisation'}]);
            } else {
              // NOTE(mgentner): Skip payment method selection if totalprice is 0 euros
              if (this.basketService.basketSubjectValue.totalPrice <= 0) {
                this.router.navigateEmbed([{name: this.embeddedIn, target: 'checkout-confirm'}]);
              } else {
                this.router.navigateEmbed([{name: this.embeddedIn, target: 'checkout-payment'}]);
              }

            }
          }
        },
        error => {
          console.log(error);
          this.logService.createLog({
            message: 'Could not set shipping method',
            apiresponse: error
          });
          if (error.status === 406) {
            if(error.error.errorCode === 'r728X') {
              this.toastr.error(this.translate.instant('toastr.basket.reservationExpired'));
              setTimeout(() => {
                localStorage.removeItem('ets-basket-' + this.config.getShopIdentifier());
                this.router.resetToHome();
              }, 2000);
            } else {
              this.toastr.error(this.errorCodeService.getErrorMessage('shippingmethod', error.error.errorCode));
            }
          } else  {
            this.toastr.error(this.errorCodeService.getErrorMessage('shippingmethod', error.error.errorCode));
          }
          this.basketService.basketLoading.next(false);
        }
      );
    } else {
      this.basketService.basketLoading.next(false);
      return;
    }
  }

  /**
   * Sends request with personalisation fields required by events with ticket personalization
   *
   * @returns void
   */
  setPersonalisation(): void {
    this.basketService.basketLoading.next(true);
    if (this.paymentMethod !== '') {
      let invalid = false;
      let counter = 0;

      for (const customer of this.personalisationData) {
        ++counter;
        if (customer.data.firstnames === '' || customer.data.firstnames === undefined) {
          invalid = true;
        }

        if (customer.data.lastnames === '' || customer.data.lastnames === undefined) {
          invalid = true;
        }

        if (invalid) {
          break;
        }
      }

      if (invalid) {
        this.toastr.error(this.translate.instant('checkout.ticketPersonalization.missingFieldsRequired') + ' - ' + this.translate.instant('checkout.ticketPersonalization.Visitor') + ' ' + counter);
        this.basketService.basketLoading.next(false);
        return;
      } else {
        this.checkoutService.setPersonalisationData(this.personalisationData).pipe(
          take(1),
        ).subscribe(
          () => {
            this.basketService.basketLoading.next(false);
            // NOTE(mgentner): Skip payment method selection if totalprice is 0 euros
            if (this.basketService.basketSubjectValue.totalPrice <= 0) {
              this.router.navigateEmbed([{name: this.embeddedIn, target: 'checkout-confirm'}]);
            } else {
              this.router.navigateEmbed([{name: this.embeddedIn, target: 'checkout-payment'}]);
            }
          },
          error => {
            console.log(error);
            this.logService.createLog({
              message: 'Could not set ticket personalization',
              apiresponse: error
            });
            this.toastr.error(this.translate.instant('toastr.checkout.personalizationError'));
            this.basketService.basketLoading.next(false);
          }
        );
      }

    }
  }

  /**
   * Sets selected paymentmethod to order
   *
   * @returns void
   */
  setPaymentMethod(): void {
    this.basketService.basketLoading.next(true);
    if (this.paymentMethod !== undefined && this.paymentMethod !== '') {
      if (this.paymentMethod !== 'sepa') {
        this.bic = null;
        this.iban = null;
          }
      if (this.paymentMethod !== 'coupon') {
        this.couponCode = null;
      }

      // * Special validation for friedrichsbau. Do not allow Ibans starting with GB or CH.
      if (this.paymentMethod === 'sepa' && this.config.getShopIdentifierSubjectValue === '1ac0d29d712e25e9c0088493e0c96212') {
        if (this.iban.toLowerCase().startsWith('ch') || this.iban.toLowerCase().startsWith('gb')) {
          if (!document.querySelector('#FrBauError')) {
            let el = this.specialRequestService.createFriedrichsbauIbanErrorMessage();
            document.querySelector('.steps').before(el);
          }

          document.querySelector('#FrBauError')?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center' });
          this.basketService.basketLoading.next(false);
          return;
        } else {
          document.querySelector('#FrBauError')?.remove();
        }
      }
      this.checkoutService.setPaymentMethod(this.paymentMethod, this.iban, this.bic, this.couponCode).pipe(
        take(1),
        switchMap((data) => {

          if (data && data.status === 'success') {

            if (data.errorCode === 'Vouc0') {
              this.voucherCodeRedeemed = true;
              this.toastr.success(this.translate.instant('toastr.checkout.voucherCodeSet'));

            }
          } else {
            this.toastr.error('Unbekannter Fehler')
          }
          return this.basketService.updateBasket();
        })
      ).subscribe(
        () => {
          this.basketService.basketLoading.next(false);

          if ((this.basketService.basketSubjectValue.totalPrice <= 0 && this.paymentMethod === 'coupon') || this.paymentMethod !== 'coupon') {
            this.router.navigateEmbed([{name: this.embeddedIn, target: 'checkout-confirm'}]);
          }
        },
        error => {
          console.log(error);
          this.logService.createLog({
            message: 'setPaymentMethod: Could not set payment method',
            apiresponse: error
          });
          if (error.error.errors === 'Please enter a valid IBAN.') {
            this.toastr.error(this.translate.instant('toastr.checkout.invalidIBan'));
          } else if (error.status === 404) {
            // No valid vouchercode found
            this.toastr.error(this.translate.instant('toastr.checkout.invalidVoucherCode'));
          } else {
            this.toastr.error(this.translate.instant('toastr.checkout.setpaymethodError'));
          }
          this.basketService.basketLoading.next(false);
        }
      );
    } else {
      this.toastr.error(this.translate.instant('toastr.checkout.noPaymentMethodSelected'));
      this.basketService.basketLoading.next(false);
    }
  }

  /**
   * Creates a new customer account
   *
   * @param $event any
   * @returns void
   */
  registerAddress($event): void {
    this.basketService.basketLoading.next(true);
    this.registerErrors = new EtsRegisterDataErrors();
    const newAddress = new EtsLoginData();

    newAddress.salutation = $event.target.salutation.value;
    newAddress.title = $event.target.title.value;
    newAddress.forename = $event.target.firstname.value;
    newAddress.surname = $event.target.lastname.value;
    newAddress.nameSuffix = $event.target.nameAddition.value;
    newAddress.street = $event.target.streetName.value;
    newAddress.houseNumber = $event.target.streetNumber.value;
    newAddress.zipCode = $event.target.zip.value;
    newAddress.city = $event.target.city.value;
    newAddress.countryInternalKey = $event.target.country.value;
    newAddress.phoneAreaCode = $event.target.areaCode.value;
    newAddress.phoneNumber = $event.target.phoneNumber.value;
    newAddress.emailAddress = $event.target.email.value ?? '';
    newAddress.emailAddressRepeat = $event.target.emailRepeat.value ?? '';
    newAddress.passwordOld = $event.target.passwordOld ? $event.target.passwordOld.value : null;
    newAddress.password = $event.target.password.value ?? '';
    newAddress.passwordRepeat = $event.target.passwordRepeat.value ?? '';

    if (newAddress.emailAddress && newAddress.emailAddressRepeat) {
      newAddress.emailAddress = newAddress.emailAddress.toLowerCase().trim();
      newAddress.emailAddressRepeat = newAddress.emailAddressRepeat.toLowerCase().trim();
    }

    if (newAddress.password && newAddress.passwordRepeat) {
      newAddress.password = newAddress.password.trim()
      newAddress.passwordRepeat = newAddress.passwordRepeat.trim()
    }

    switch (this.config.getShopIdentifier()) {
      case '8284e02215089cb7d8cf019e5697c390':
      case '53a8edde17f2780485ccbdf81eb74e64':
      case 'f182d9ad06e9bda677d7a3046a0dc7ab':
      case '5306f1fd3425bbd1907c34d28fa82da3':
      case 'e26ba42994bef547cee9e624bb48dfe6':
        let department = $event.target.department.value ?? '';
        let company = $event.target.company.value ?? '';

        if (department === '') {
          console.log('Department or company is empty');
          this.registerErrors.department = 'Bitte geben Sie eine Abteilung an.';
          this.toastr.error('Das Feld Abteilung darf nicht leer sein');

        }
        if (company === '') {
          this.registerErrors.company = 'Bitte geben Sie eine Firma an.';
          this.toastr.error('Das Feld Firma darf nicht leer sein');
        }

        if (company === '' || department === '') {
          this.basketService.basketLoading.next(false);
          return;
        }

        let name4 = company + ' / ' + department;

        if (name4.length > 60) {
          this.toastr.error('Die Felder Abteilung und Firma dürfen zusammen nicht länger als 60 Zeichen sein.');
          this.basketService.basketLoading.next(false);
          return;
        }
        newAddress.name4 = name4;
        break;

      default:
        newAddress.name4 = '';
        break;
    }


    let err = false;
    if (newAddress.salutation === undefined || newAddress.salutation === null || $event.target.salutation.value === '') {
      this.registerErrors.salutation = this.translate.instant('errorMessage.register.salutationError');
      err = true;
    }
    if (newAddress.forename === 'undefined' || newAddress.forename === null || newAddress.forename === '') {
      this.registerErrors.forename = this.translate.instant('errorMessage.register.forenameError');
      err = true;
    }
    if (newAddress.surname === 'undefined' || newAddress.surname === null || newAddress.surname === '') {
      this.registerErrors.surname = this.translate.instant('errorMessage.register.surnameError');
      err = true;
    }
    if (newAddress.street === 'undefined' || newAddress.street === null ||  newAddress.street === '') {
      this.registerErrors.street = this.translate.instant('errorMessage.register.streetError');
      err = true;
    }
    if (newAddress.houseNumber === 'undefined' || newAddress.houseNumber === null || newAddress.houseNumber === '') {
      this.registerErrors.houseNumber = this.translate.instant('errorMessage.register.housenumberError');
      err = true;
    }
    if (newAddress.zipCode === 'undefined' || newAddress.zipCode === null || newAddress.zipCode === '') {
      this.registerErrors.zipCode = this.translate.instant('errorMessage.register.zipCodeError');
      err = true;
    }
    if (newAddress.city === 'undefined' || newAddress.city === null || newAddress.city === '') {
      this.registerErrors.city = this.translate.instant('errorMessage.register.cityError');
      err = true;
    }
    if (newAddress.emailAddress === 'undefined' || newAddress.emailAddress === null || newAddress.emailAddress === '') {
      this.registerErrors.emailAddress =  this.translate.instant('errorMessage.register.emailError');
      err = true;
    }
    if (newAddress.emailAddressRepeat === 'undefined' || newAddress.emailAddressRepeat === null || newAddress.emailAddressRepeat === '') {
      this.registerErrors.emailAddressRepeat = this.translate.instant('errorMessage.register.emailError');
      err = true;
    }
    if (newAddress.password === 'undefined' || newAddress.password === null || newAddress.password === '') {
      this.registerErrors.password = this.translate.instant('errorMessage.register.passwordError');
      err = true;
    }
    if (newAddress.passwordRepeat === 'undefined' || newAddress.passwordRepeat === null || newAddress.passwordRepeat === '') {
      this.registerErrors.passwordRepeat = this.translate.instant('errorMessage.register.passwordError');
      err = true;
    }
    if (newAddress.emailAddress !== newAddress.emailAddressRepeat) {
      this.registerErrors.emailAddressRepeat = this.translate.instant('errorMessage.register.emailRepeatError');
      err = true;
    }
    if (newAddress.password !== newAddress.passwordRepeat) {
      this.registerErrors.passwordRepeat = this.translate.instant('errorMessage.register.passwordRepeatError');
      err = true;
    }
    if (newAddress.countryInternalKey === undefined || newAddress.countryInternalKey === null || $event.target.country.value === '') {
      this.registerErrors.country = this.translate.instant('errorMessage.register.countryError');
      err = true;
    }

    if (this.addInfoReq) {
      if (newAddress.nameSuffix === '') {

        if (this.addInfoDesc !== '') {
          this.toastr.error('Bitte füllen Sie das Feld: ' + this.addInfoDesc + ' aus');
        } else {
          this.toastr.error('Bitte füllen Sie das Feld "Zusatzinformationen" aus');
        }

        err = true;
      }
    }

    if (this.partner_id === 333763) {
      if (newAddress.nameSuffix === '') {
        this.toastr.error('Bitte geben Sie Ihre Porsche-Mitarbeiternummer ein.');
        err = true;
      }
    }

    if (err) {
      this.basketService.basketLoading.next(false);
      return;
    }

    this.loginService.registerCustomer(newAddress).pipe(
      take(1),
      switchMap( () => {
        return this.loginService.doLogin(newAddress.emailAddress.toLowerCase(), newAddress.password);
      }),
      ).subscribe(
      data => {
        if (sessionStorage.getItem('ets-promotionCode-' + this.config.getShopIdentifier())) {
          this.couponService.setCouponCode(this.promotionCode, localStorage.getItem('ets-token-' + this.config.getShopIdentifier())).subscribe(
            () => {
            },
            error => {
              console.log(error);
            }
          );
        }
        this.basketService.basketLoading.next(false);
        if (data) {
          if (localStorage.getItem('ets-basket-' + this.config.getShopIdentifier()) !== undefined
            && localStorage.getItem('ets-basket-' + this.config.getShopIdentifier()) !== null) {
            this.router.navigateEmbed([{name: this.embeddedIn, target: 'checkout-shipping'}]);
          } else {
            this.router.navigateEmbed([{name: this.embeddedIn, target: 'profile-profile'}]);
          }

        }
      },
      error => {
        console.log(error);
        this.logService.createLog({
          message: 'registerAddress: Could not create new customer',
          apiresponse: error,
          parameters: newAddress
        });
        if (error.status && error.status === 404) {
          switch (error.error.errorCode) {
            case 'r9BTP':
              this.toastr.error(this.translate.instant('errorMessage.checkout.sessionTimeoutError'));
              setTimeout(() => {
                this.checkoutService.clearLocalStorage();
                this.router.resetToHome();
              }, 2000);
              break;
            case 'P16101':
              this.toastr.error(this.translate.instant('toastr.porsche.invalidNumber'));
              break;
            case 'P16102':
              this.toastr.error(this.translate.instant('toastr.porsche.numberNotFound'));
              break;

            default:
              this.toastr.error('Unbekannter Fehler');
              break;
          }
        }

        if (error.status && error.status === 409) {
          let errorMessage = this.translate.instant('toastr.register.emailTaken') + '\n';
          errorMessage += ' ' + this.translate.instant('toastr.register.emailTakenPasswordText1');
          errorMessage += ' <a href="' + this.router.urlString([{name: this.embeddedIn, target: 'checkout-password'}]) + '">' + this.translate.instant('toastr.register.emailResetPassword') + '</a>';
          errorMessage += ' ' + this.translate.instant('toastr.register.emailTakenPasswordText2');
          this.toastr.error(errorMessage, '', {enableHtml: true, disableTimeOut: true});
          this.registerErrors.emailAddress = this.translate.instant('errorMessage.register.emailTakenError');
        } else {
          this.toastr.error(this.translate.instant('toastr.register.genericError'));
        }
        this.basketService.basketLoading.next(false);
      }
    );
  }

  /**
   * Opens shippingmethod infos without selecting them
   *
   * @param $event any
   * @param id EtsChosenShipping
   * @returns void
   */
  showShippingInfo($event, id: EtsChosenShipping): void {
    let panel = $event.target;

    while (!panel.classList.contains('etsTitle')) {
      panel = panel.parentElement;
    }

    panel = panel.nextElementSibling;

    if (!panel.style.maxHeight) {
      for (const ele of panel.parentElement.parentElement.getElementsByTagName('LI')) {
        ele.classList.remove('etsOpened');
        const content = ele.getElementsByClassName('etsContent');
        content[0].style.maxHeight = null;
      }

      if (id.description !== '') {
        panel.parentElement.classList.add('etsOpened');
        panel.style.maxHeight = panel.scrollHeight + 'px';
      }

    } else {
      panel.parentElement.classList.remove('etsOpened');
      panel.style.maxHeight = null;
    }
    // Needs to be here because of event bubbling
    $event.stopPropagation();
  }

  /**
   * Opens accordion with details for selected shippingmethod
   *
   * @param $event any
   * @param id EtsChosenShipping
   * @returns void
   */
  accordionClick($event, id: EtsChosenShipping): void {
    this.shippingMethod = id.id;
    let panel = $event.target;
    if (!panel.classList.contains('etsTitle')) {
      panel = panel.parentElement;
      if (!panel.classList.contains('etsTitle')) {
        panel = panel.parentElement;
        if (!panel.classList.contains('etsTitle')) {
          panel = panel.parentElement;
        }
      }
    }
    panel = panel.nextElementSibling;

    if (!panel.style.maxHeight) {
      for (const ele of panel.parentElement.parentElement.getElementsByTagName('LI')) {
        ele.classList.remove('etsOpened');
        ele.classList.remove('active');
        const content = ele.getElementsByClassName('etsContent');
        content[0].style.maxHeight = null;
      }

      if (id.description !== '') {
        panel.parentElement.classList.add('etsOpened');
        panel.style.maxHeight = panel.scrollHeight + 'px';
      }
      panel.parentElement.classList.add('active');

    } else if ($event.target.classList.contains('infoBtn')) {
      panel.parentElement.classList.remove('etsOpened');
      panel.parentElement.classList.remove('active');
      panel.style.maxHeight = null;
      this.shippingMethod = 0;
    }
    // this.setShippingMethod(false);
  }

  /**
   * Opens accordion with details and addition input field for selected paymentmethod
   *
   * @param $event any
   * @param id string
   * @returns void
   */
  accordionPaymentClick($event, id: string): void {
    this.paymentMethod = id;
    let panel = $event.target;
    if (!panel.classList.contains('etsTitle')) {
      panel = panel.parentElement;
      if (!panel.classList.contains('etsTitle')) {
        panel = panel.parentElement;
        if (!panel.classList.contains('etsTitle')) {
          panel = panel.parentElement;
        }
      }
    }
    panel = panel.nextElementSibling;

    if (!panel.style.maxHeight) {
      for (const ele of panel.parentElement.parentElement.getElementsByTagName('LI')) {
        ele.classList.remove('etsOpened');
        ele.classList.remove('active');
        const content = ele.getElementsByClassName('etsContent');
        content[0].style.maxHeight = null;
      }

      if (id === 'sepa' || id === 'coupon') {
        panel.parentElement.classList.add('etsOpened');
        panel.style.maxHeight = panel.scrollHeight + 'px';
      }

      panel.parentElement.classList.add('active');

    } else if ($event.target.classList.contains('infoBtn')) {
      panel.parentElement.classList.remove('etsOpened');
      panel.parentElement.classList.remove('active');
      panel.style.maxHeight = null;
      this.paymentMethod = '';
    }
  }

  /**
   * Redirect customer to telecash payment page
   * Used for paymentmethods: Giropay, MasterCard and Visa
   *
   * @param $event any
   * @returns void
   */
  submitTelecash($event): void {
    if (!this.specialRequestService.checkBasketItems()) {
      this.router.navigateEmbed([{name: this.embeddedIn, target: 'basket-details'}]);
      return;
    }
    if (this.allowAGB) {

      // ! Special Case for Bosch Shops 1-5
      if (this.boschShop) {
        if (!this.photoConsent) {
          this.toastr.error('Bitte bestätigen Sie die Erlaubnis zur Fotoaufnahme.');
          return;
        }

        if (this.meetAndGreet) {
          sessionStorage.setItem('ets-' + this.config.getShopIdentifier() + '-meetandgreet', '1');
        }
      }

      this.basketService.basketLoading.next(true);

      this.checkoutService.getTelecashData().subscribe( () => {
        if (this.isGuestOrder) {
          sessionStorage.setItem('ets-isTelecashGuestCheckout-' + this.config.getShopIdentifier(), '1');
        }
        this.checkoutService.resetReservationTimer().subscribe(
          () => {
            $event.target.submit();
            this.basketService.basketLoading.next(false);
        },
          error => {
            console.log(error);
            this.logService.createLog({
              message: 'Unable to reset reservation timer',
              apiresponse: error
            });
            this.basketService.basketLoading.next(false);
        });
      });
    } else {
      this.showAGBError = true;
    }
  }

  /**
   * Called after checkout if customer selected IBAN or Couponcode as paymentmethod
   *
   * @returns void
   */
  noTelecashPayment(): void {
    if (!this.specialRequestService.checkBasketItems()) {
      this.router.navigateEmbed([{name: this.embeddedIn, target: 'basket-details'}]);
      return;
    }
    if (this.allowAGB) {
      this.basketService.basketLoading.next(true);

      // ! Special Case for Bosch Shops 1-5
      if (this.boschShop) {
        if (!this.photoConsent) {
          this.toastr.error('Bitte bestätigen Sie die Erlaubnis zur Fotoaufnahme.');
          return;
        }

        if (this.meetAndGreet) {
          sessionStorage.setItem('ets-' + this.config.getShopIdentifier() + '-meetandgreet', '1');
        }
      }

      this.checkoutService.setPaid().subscribe(
        () => {
          this.basketService.basketLoading.next(false);
          this.router.navigateEmbed([{name: this.embeddedIn, target: 'checkout-finish'}]);
          this.ngOnInit();
        },
        err => {
          this.logService.createLog({
            message: 'noTelecashPayment: An error has occurred during payment	',
            apiresponse: err
          });
          this.basketService.basketLoading.next(false);
          console.log(err);
          this.toastr.error(this.translate.instant('toastr.payment.payError'));
        }
      );
    } else {
      this.showAGBError = true;
    }
    if (!this.isCustomer) {
      this.loginService.doLogout();
    }
  }

  checkAGB($event): void {
    this.allowAGB = $event.target.checked;
  }

  changeAGB(): void {
    this.allowAGB = !this.allowAGB;
  }

  /**
   * Redirects customer to forget password page
   *
   * @returns void
   */
  passwordForgot(): void {
    const urlParams = new URLSearchParams(
      window.location.search
    );

    // * If shops used as widgets check for additional uri query parameters like eventid
    let optParameters = '';
    urlParams.forEach((value, key) => {
      if (value !== 'checkout-password') {
        optParameters += '&' + key + '=' + value;
      }
    });
    optParameters = encodeURI(optParameters);
    this.passwordRecoveryLoading = true;

    // * Build password reset link with EmbeddedIn Parameter needed for widget implementation
    let passwordResetLink = location.href.substring(0, location.href.lastIndexOf('?'));
    passwordResetLink += '?' + this.router.generateUrlName(this.embeddedIn) + '=checkout-passwordrecovery' + optParameters;
    passwordResetLink = encodeURIComponent(passwordResetLink);

    this.checkoutService.triggerForgotPassword(this.passwordEmail, passwordResetLink).pipe(take(1)).subscribe(
      data => {
        if (data) {
          this.passwordRecoveryLoading = false;
          this.toastr.success(this.translate.instant('toastr.resetPassword.resetInfo'));
          setTimeout(() => {
            this.router.navigateEmbed([{name: this.embeddedIn, target: '' }]);
          }, 1000);
        }
      },
      error => {
        this.logService.createLog({
          message: 'passwordForgot: Could not create customer password reset link',
          apiresponse: error,
          parameters: {
            email: this.passwordEmail
          }
        });
        console.log(error);
        if (error.status === 404) {
          this.toastr.error(this.translate.instant('toastr.resetPassword.unknownEmail'))
        } else {
          this.toastr.error(this.translate.instant('toastr.resetPassword.genericError'));
        }
        this.passwordRecoveryLoading = false;
      }
    );
  }

  /**
   * Changes customers password
   *
   * @returns void
   */
  passwordForgotReset(): void {
    this.passwordRecoveryLoading = true;
    let hash = '';
    const urlParams = new URLSearchParams(
      window.location.search
    );

    urlParams.forEach((value, key) => {
      if (key === 'hash') {
        hash = value;
      }
    });

    if (this.password !== this.passwordRepeat) {
      this.toastr.error(this.translate.instant('toastr.resetPassword.passwordmatchError'));
      this.passwordRecoveryLoading = false;
      return;
    }

    this.checkoutService.triggerForgotPasswordReset(hash, this.password, this.passwordRepeat).pipe(take(1)).subscribe(
      data => {
        if (data) {
          this.passwordRecoveryLoading = false;
          this.toastr.success(this.translate.instant('toastr.resetPassword.resetSuccess'));
          setTimeout(() => {
            this.router.navigateEmbed([{name: this.embeddedIn, target: ''}]);
          }, 1500);
        }
      },
      error => {
        this.logService.createLog({
          message: 'passwordForgotReset: Could not reset customer password',
          apiresponse: error
        });
        console.log(error);
        this.passwordRecoveryLoading = false;
        this.toastr.error(this.translate.instant('toastr.resetPassword.genericError'));
      }
    );
  }

  /**
   * Opens/Closes password change form at address step
   *
   * @returns void
   */
  togglePasswordForm(): void {
    this.showPasswordForm = this.showPasswordForm ? false : true;
  }

  /**
   * Validates promotioncode
   *
   * @returns void
   */
  checkPromotionCode(): void {
    this.couponService.validateCouponCode(this.promotionCode).subscribe((valid) => {
      if (valid) {

        if (localStorage.getItem('ets-login-token-' + this.config.getShopIdentifier())) {
          this.couponService.setCouponCode(this.promotionCode, localStorage.getItem('ets-login-token-' + this.config.getShopIdentifier())).subscribe();
        }
        this.toastr.success(this.translate.instant('couponCodes.errorMessage.validCouponCode'));
        setTimeout(() => {
          sessionStorage.setItem('ets-promotionCode-' + this.config.getShopIdentifier(), this.promotionCode);
          this.router.navigateEmbed([{name: this.embeddedIn, target: 'checkout-login' }]);
        }, 2000);
      } else {
        if (this.couponService.etsCouponSettings.cancelText === undefined
            || this.couponService.etsCouponSettings.cancelText === null
            || this.couponService.etsCouponSettings.cancelText === ''
        ) {
            this.toastr.error(this.translate.instant('couponCodes.errorMessage.invalidCouponCode'));
          } else {
            this.toastr.error(this.couponService.etsCouponSettings.cancelText, '', {disableTimeOut: true, enableHtml: true});
          }
      }
    },
    (error) => {
      if (error.status !== 401) {
        console.log(error);
      }
      if (this.couponService.etsCouponSettings.cancelText === undefined
          || this.couponService.etsCouponSettings.cancelText === null
          || this.couponService.etsCouponSettings.cancelText === ''
      ) {
          this.toastr.error(this.translate.instant('couponCodes.errorMessage.invalidCouponCode'));
        } else {
          this.toastr.error(this.couponService.etsCouponSettings.cancelText, '', {disableTimeOut: true, enableHtml: true});
        }
    });
  }

  /**
   * Shows/Hides value of specific password input field
   *
   * @param input string
   * @returns void
   */
  showPassword(input: string): void {

    switch (input) {
      case 'addressPasswordOld':
        this.addressShowOldPassword = (this.addressShowOldPassword) ? false : true;
        break;
      case 'addressPassword':
        this.addressShowPassword = (this.addressShowPassword) ? false : true;
        break;
      case 'addressPasswordConf':
        this.addressShowPasswordConf = (this.addressShowPasswordConf) ? false : true;
        break;
      case 'registerPassword':
        this.registerShowPassword = (this.registerShowPassword) ? false : true;
        break;
      case 'registerPasswordConf':
        this.registerShowPasswordConf = (this.registerShowPasswordConf) ? false : true;
        break;
      default:
        break;
    }
  }

  /**
   * Set customer promotioncode.
   *
   */
  redeemPromoCode(): void
  {
    if (this.promotionCodeForm.invalid || this.promotionCodeForm.value.promotioncode === '') {
      return;
    }

    this.basketService.redeemPromotionCode(this.promotionCodeForm.value.promotioncode).subscribe( (data: boolean) => {
      this.basketService.updateBasket().subscribe( () => {
        this.basketService.basketLoading.next(false);
      });
      if (data) {
        this.toastr.success(this.translate.instant('toastr.promotioncodes.redeemSuccess'));
      }
    },
    (err) => {
      this.basketService.basketLoading.next(false);
      this.logService.createLog({
        message: 'redeemPromoCode: Could not redeem promotioncode',
        apiresponse: err,
        parameters: {promotioncode: this.promotionCodeForm.value.promotioncode}
      });
      console.log(err);
      this.toastr.error(this.basketService.getPromotioncodeErrors(err.status, err.error.errorCode));
    });
  }

  /**
   * Removes set promotioncode from basket.
   */
  removePromoCode(): void
  {
    this.basketService.cancelPromotionCode().subscribe( () => {
      this.basketService.basketLoading.next(false);
      this.toastr.success(this.translate.instant('toastr.promotioncodes.cancelSuccess'));
      this.basketService.basketLoading.next(true);
      this.basketService.updateBasket().subscribe( (data: boolean) => {
        if (data) {
          this.basketService.basketLoading.next(false);
          this.promotionCodeForm.reset();
        } else {
          throw new Error('Could not update basket');
        }
      },
      (err: Error) => {
        this.logService.createLog({
          message: 'removePromoCode: Unable to update cart',
          apiresponse: err
        })
        console.log('Error:', err);
      });
    },
    (err: Error) => {
      this.logService.createLog({
        message: 'removePromoCode: Could not remove promotioncode from cart.',
        apiresponse: err
      });
      console.log('Error:', err);
    });
  }

  downloadPrintAtHomeTicket(): void {
    this.printAtHomeLoading = true;
    this.checkoutService.getPrintAtHomeTicket().subscribe( (file: Blob) => {
      const bufferBlob = new Blob([file], {type: 'application/pdf'});

      const data = window.URL.createObjectURL(bufferBlob);

      const link = document.createElement('a');
      link.href = data;
      link.download = 'printathome_ticket.pdf';

      link.dispatchEvent( new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

      // For Firefox Browser needs a little delay
      setTimeout( () => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
      this.printAtHomeLoading = false;
    },
    error => {
      this.logService.createLog({
        message: 'downloadPrintAtHomeTicket: Failed to download P@H ticket.',
        apiresponse: error
      });
      console.log(error);
      this.toastr.error(this.translate.instant('errorMessage.checkout.printAtHomeError'));
      this.printAtHomeLoading = false;
    });
  }
}
