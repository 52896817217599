import {Inject, Injectable} from '@angular/core';
import {EtsDetailPackageData} from './ets-detail-package-data';
import {BehaviorSubject} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {EtsConfigService} from '../ets-config-service/ets-config.service';

@Injectable({
  providedIn: 'root'
})
export class EtsDetailPackageService {

  detailDataStorage: {[identifier: string]: EtsDetailPackageData};
  subject: {[identifier: string]: BehaviorSubject<EtsDetailPackageData>};

  public constructor(@Inject('ETS_API_URL') public apiUrl: string, private http: HttpClient, private config: EtsConfigService) {
    this.detailDataStorage = {};
    this.subject = {};
  }

  public loadPackageDetailData(identifier: string): BehaviorSubject<EtsDetailPackageData> {
    if (this.subject.hasOwnProperty(identifier)) {
      return this.subject[identifier];
    } else {
      this.subject[identifier] = new BehaviorSubject<EtsDetailPackageData>(null);
    }

    this.config.getShopIdentifierSubject().subscribe(conf => {
      if (conf) {
        const promise = this.http.get(this.apiUrl + 'package/' + parseInt(identifier.replace(/777/g, '-'), 10), {headers: {partnershopId: this.config.getShopIdentifier()}}).toPromise();

        promise.then((data: any) => {
          if (data.data && data.data.package_id) {
            const event = data.data;
            const location = data.data.location;
            const images = data.data.images ? data.data.images : [];
            const events = data.data.event_dates;
            const imageList = [];
            const eventList = [];

            for (const imageData of images) {
              if (imageData.paths) {
                for (const path of imageData.paths) {
                  if (path.type === 'large') {
                    imageList.push({
                      url: path.url,
                      title: imageData.title,
                      copyright: imageData.copyright,
                    });
                  }
                }
              }
            }

            for (const eventData of events) {
              eventList.push({
                eventDate: eventData.event_date_date_time,
                eventLocation: eventData.location_name,
                eventOrganizerName: eventData.organizer_name,
                showEventTime: event?.events[eventData.event_id]?.show_event_time ?? 0
              });
            }

            this.detailDataStorage[identifier] = {
              base: {
                artist: event.package_title
              },
              headliner: {
                photoTitle: 'Photo Title',
                photoCopyright: 'Copyright',
                photoUrls: imageList,
                infoTitle: event.package_title,
                infoSubTitle: null,
                infoDateString: event.package_date,
                infoLocationString: location.location_meta_title,
                infoPriceBegin: null,
                infoPriceEnd: null,
                shortDescription: event.package_description,
                shortDescriptionEN: event.package_description_en,
                eventLink: event.event_link,
                eventLinkTarget: event.event_link_target,
                eventLinkText: event.event_link_text,
              },
              infoBox: {
                moreInfoHTML: '',
                artistHTML: '',
                locationHTML: '',
                packageInfoText: event.package_info_text
              },
              events: eventList
            };
          }
          this.subject[identifier].next(this.detailDataStorage[identifier]);
        });

        promise.catch((reason: HttpErrorResponse) => {
           console.log(reason);
           if (reason.error && reason.error.failure && reason.error.failure === 'failure') {
             if (reason.error.errors === 'Event not found.') {
               this.detailDataStorage[identifier].errorText = 'Veranstaltung nicht gefunden.';
               this.subject[identifier].next(this.detailDataStorage[identifier]);
             }
             if (reason.error.errors === 'partnershop not found.') {
               this.detailDataStorage[identifier].errorText = 'Keine Veranstaltungen verfügbar';
               this.subject[identifier].next(this.detailDataStorage[identifier]);
             }
             if (reason.error.errors === 'This partnershop does not exist.') {
               this.detailDataStorage[identifier].errorText = 'Kein Partnershop verfügbar';
               this.subject[identifier].next(this.detailDataStorage[identifier]);
             }
             if (reason.error.errors === 'Error connecting to gateway.') {
               this.detailDataStorage[identifier].errorText = 'Es ist ein Technischer Fehler aufgetreten';
               this.subject[identifier].next(this.detailDataStorage[identifier]);
             }
           }
        });
      }
    });

    return this.subject[identifier];
  }

  public hasDetailData(search: string): boolean {
    return this.detailDataStorage.hasOwnProperty(search);
  }
}
